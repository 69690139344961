import React, { useState } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, Avatar } from '@mui/material';
import styles from './FormComponent.module.css';
import URLFieldComponent from '../Fields/URLFieldComponent'; 
import DropdownFieldComponent from '../Fields/DropdownFieldComponent'; 
import TextFieldComponent from '../Fields/TextFieldComponent'; 
import UTMGenerator from './UTMGenerator'; 
import UTMViewer from './UTMViewer'; 
import { dropdownOptions1, dropdownOptions2, dropdownOptions3, dropdownOptions4, dropdownOptions5, dropdownOptions6 } from './dropdownOptions'; 
import UTMContent from './UTMContent';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';

const FormComponent = () => {
  const [landingPageUrl, setLandingPageUrl] = useState('');
  const [dropdownValue1, setDropdownValue1] = useState(null);
  const [dropdownValue2, setDropdownValue2] = useState(null);
  const [dropdownValue3, setDropdownValue3] = useState(null);
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [dropdownValue4, setDropdownValue4] = useState(null);
  const [dropdownValue5, setDropdownValue5] = useState(null);
  const [dropdownValue6, setDropdownValue6] = useState(null);



  const utmParams = {
    landingPageUrl,
    utm_source: dropdownValue1,
    utm_medium: dropdownValue2,
    utm_objective: dropdownValue3,
    utm_campaign: textFieldValue1,
    utm_content: textFieldValue2,
    utm_term: textFieldValue3,
    utm_date: dropdownValue4,
    utm_lob: dropdownValue5,
    utm_country: dropdownValue6,
  };

  return (
    <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
      <CardHeader
        avatar={
          <Avatar 
            alt="Claro UTM Builder logo" 
            src="/claro.svg" 
            sx={{ width: 100, height: 100, borderRadius: 3 }}
          />
        }
        title={<Typography variant="h4">Generador UTMs de Claro</Typography>}
        subheader={<Typography variant="body1">Utilice este formulario para crear el URL de tu campaña Claro (o Google UTM)</Typography>}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <form>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="caption" sx={{ color: 'gray', textAlign: 'center', mt: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
              <span style={{ fontWeight: 'bold' }}>Configuracion:</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardCapslockIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} /> 
                no mayusculas permitidas, 
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <SpaceBarIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} />
                espacios como "-" (guiones)
              </span>
            </Typography>

            <URLFieldComponent 
              label="Landing page URL" 
              required={true} 
              value={landingPageUrl}
              onChange={setLandingPageUrl}
            />
            <Box display="flex" flexDirection="row" gap={2}>
              <DropdownFieldComponent 
                label="Source" 
                options={dropdownOptions1} 
                value={dropdownValue1} 
                onChange={setDropdownValue1}
                required={true}
                hint="Clic para seleccionar su fuente"
                helperText="El referente (es decir, facebook, google)" 
              />
              <DropdownFieldComponent 
                label="Medium" 
                options={dropdownOptions2} 
                value={dropdownValue2} 
                onChange={setDropdownValue2}
                required={true}
                hint="Clic para seleccionar su medio"
                helperText="Medio de marketing (es decir, display, vídeo, cpc)" 
              />

            </Box>
            <Box display="flex" flexDirection="row" gap={2}>
              <DropdownFieldComponent 
                label="Objetivo" 
                options={dropdownOptions3} 
                value={dropdownValue3} 
                onChange={setDropdownValue3}
                required={true}
                hint="Clic para seleccionar tu objetivo."
                helperText="Objetivo abreviado (es decir, awareness)"
              />
              <DropdownFieldComponent 
                label="Línea de Negocio" 
                options={dropdownOptions5} 
                value={dropdownValue5} 
                onChange={setDropdownValue5}
                required={true}
                hint="Clic para seleccionar tu línea."
                helperText="Sector en el que se prestan servicios"
              />
              <TextFieldComponent
                label="Nombre de Campaña"
                value={textFieldValue1}
                onChange={setTextFieldValue1}
                required={true}
                hint="Escribe el nombre de tu campaña"
                helperText="Identificador único de campaña"
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={2}>
              <TextFieldComponent
                label="Nombre del Adset"
                value={textFieldValue2}
                onChange={setTextFieldValue2}
                hint="Escribe el nombre de tu Adset"
                helperText="El grupo de anuncios (aka utm content)"
              />
              <TextFieldComponent
                label="Nombre del Ad"
                value={textFieldValue3}
                onChange={setTextFieldValue3}
                hint="Escribe el nombre de tu Ad"
                helperText="Nombre de anuncio único (aka utm term)"
              />
              <DropdownFieldComponent 
                label="Fecha" 
                options={dropdownOptions4} 
                value={dropdownValue4} 
                onChange={setDropdownValue4}
                hint="Seleccione el mes de lanzamiento"
                helperText="Mes de lanazamiento del Ad"
              />
              <DropdownFieldComponent 
                label="Pais" 
                options={dropdownOptions6} 
                value={dropdownValue6} 
                onChange={setDropdownValue6}
                hint="Clic para seleccionar su pais"
                helperText="Pais en que se lanza el UTM" 
              />
            </Box>
            <UTMGenerator utmParams={utmParams}>
              {(utm) => (
                <div className={styles.utmViewerWrapper}>
                  <UTMViewer utm={utm} />
                </div>
              )}
            </UTMGenerator>
          </Box>
        </form>
      </CardContent>
      <UTMContent />
    </Card>
  );
};

export default FormComponent;
