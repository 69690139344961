// dropdownOptions.js

const createOption = (label, hint = '') => ({ label, hint });

export const dropdownOptions1 = [
  'chapin tv', 'digital factory', 'dinamic media', 'dv360', 'eikon',
  'facebook&instagram', 'google', 'guatemala.com', 'illumin', 'linkedin',
  'ookla', 'prensa libre', 'soy502', 'waze', 'youtube'
].map(label => createOption(label));

export const dropdownOptions2 = [
  'cpc', 'cpco', 'cpd', 'cpma', 'cpm', 'cpv', 'influencer'
].map(label => createOption(label));

export const dropdownOptions3 = [
  { label: 'AL', hint: 'Alcance' },
  { label: 'AW', hint: 'Awareness' },
  { label: 'KO', hint: 'Conversion' },
  { label: 'DE', hint: 'Descarga' },
  { label: 'RE', hint: 'Reproduccion' },
  { label: 'TR', hint: 'Trafico' },
];

export const dropdownOptions4 = [
  { label: '01', hint: 'enero' },
  { label: '02', hint: 'febrero' },
  { label: '03', hint: 'marzo' },
  { label: '04', hint: 'abril' },
  { label: '05', hint: 'mayo' },
  { label: '06', hint: 'junio' },
  { label: '07', hint: 'julio' },
  { label: '08', hint: 'agosto' },
  { label: '09', hint: 'septiembre' },
  { label: '10', hint: 'octubre' },
  { label: '11', hint: 'noviembre' },
  { label: '12', hint: 'diciembre' },
];

export const dropdownOptions5 = [
  { label: 'CO', hint: 'Corporativo' },
  { label: 'IN', hint: 'Institucional' },
  { label: 'PO', hint: 'Pospago' },
  { label: 'PR', hint: 'Prepago' },
  { label: 'SF', hint: 'Servicios Fijos' },
  { label: 'VA', hint: 'Vas' },
];

export const dropdownOptions6 = [
  { label: 'GUA', hint: 'Guatemala' },
  { label: 'SLV', hint: 'El Salvador' },
  { label: 'HON', hint: 'Honduras' },
  { label: 'NIC', hint: 'Nicaragua' },
  { label: 'CR', hint: 'Costa Rica' },
];
