import React from 'react';
import styles from './UTMContent.module.css';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';

const URLStructure = () => {
  return (
    <Container style={{ padding: '0px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '16px 0', flexWrap: 'wrap' }}>
        <span style={{ marginRight: '8px', fontWeight: 'bold' }}>Form's URL structure:</span>
        <Chip label="Landing Page URL" variant="outlined" style={{ backgroundColor: '#e0f7fa', marginRight: '8px' }} />
        <span>?</span>
        <span>utmsource=</span>
        <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2', margin: '0 8px' }} />
        <span>&utm_medium=</span>
        <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7', margin: '0 8px' }} />
        <span>&utmcampaign=</span>
        <Chip label="objectivo" variant="outlined" style={{ backgroundColor: '#c8e6c9', margin: '0 8px' }} />
        <span>_</span>
        <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9', margin: '0 8px' }} />
        <span>&utm_content=</span>
        <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc', margin: '0 8px' }} />
        <span>&utm_term=</span>
        <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb', margin: '0 8px'  }} />
        <span>_</span>
        <Chip label="fecha" variant="outlined" style={{ backgroundColor: '#bbdefb', margin: '0 8px'  }} />

      </div>
    </Container>
  );
};

const UTMContent = () => {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Explicación de los parámetros de URL de campaña de Claro (UTM)</h1>
        <p className={styles.subtitle}>
          Esta tabla ofrece una explicación detallada y un ejemplo de cada parámetro de UTMs utilizados en Google Analytics. Descubra cómo este creador de UTMs para Claro, permite a nuestros equipos de mercadeo crear URLs que cumplan con nuestras mejores prácticas, lo que garantiza un seguimiento y una medición precisos del rendimiento de su campaña de marketing digital directamente en{' '}
          <a href="https://analytics.google.com/analytics/web/" target="_blank" rel="noopener noreferrer" className={styles.link}>
            Google Analytics
          </a>
          .
        </p>
      </header>

      <URLStructure />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Parametro</th>
            <th className={styles.th}>Requirido</th>
            <th className={styles.th}>Ejemplo</th>
            <th className={styles.th}>Descripcion</th>
          </tr>
        </thead>
        <tbody>

          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Source
              <div>
                <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Si</td>
            <td className={styles.td}>google, facebook, instagram</td>
            <td className={styles.td}>De dónde proviene su tráfico.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Medium
              <div>
                <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Si</td>
            <td className={styles.td}>cpc, banner, display</td>
            <td className={styles.td}>En qué tipo de medio se utiliza el enlace.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              URL Objective
              <div>
                <Chip label="objective" variant="outlined" style={{ backgroundColor: '#c8e6c9' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Si</td>
            <td className={styles.td}>awareness (aw), consideration (co), conversion (ko)</td>
            <td className={styles.td}>El objetivo de la URL (Nota: el objetivo se combina con la campaña (es decir, "objective_campaign") en el nombre de la campaña UTM).</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaña
              <div>
                <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Si</td>
            <td className={styles.td}>vacaciones_venta, verano, nuevo_lanzamiento</td>
            <td className={styles.td}>La promoción o estrategia específica.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Nombre de Ad Set
              <div>
                <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>zapatos_hombre, milenario</td>
            <td className={styles.td}>Conocido como UTM Content. Un subgrupo o variante dentro de una campaña.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Ad Name
              <div>
                <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>azul, foto_familia</td>
            <td className={styles.td}>Conocido como UTM Term. Utilice términos claros y descriptivos para el nombre único (Nota: el nombre del anuncio se combina con la fecha (es decir, "adname_fecha") en el término UTM).</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Fecha
              <div>
                <Chip label="fecha" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>01 (enero)</td>
            <td className={styles.td}>El mes de lanzamiento de la UTM para mejorar la segmentación.</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.subtitle}>
        Para obtener más información sobre las mejores prácticas de campañas de marketing UTM, visite{' '}
        <a href="https://utmsmartmanager.com/how-to-build-store-and-track-utm-campaign-links-like-a-master-digital-marketer/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          How to Build, Store, and Track UTM Campaign links like a master digital marketer
        </a>{' '}
      </p>
      <p className={styles.caption}>
      Acerca de UTM Smart Manager: UTM Smart Manager es el mejor creador de formularios y rastreador de campañas de su clase para los especialistas en marketing de Google Analytics de Fortune 500. Con convenciones de nomenclatura definidas, reglas de URL aplicadas y seguimiento de enlaces de primer nivel, Smart Manager ayuda a los especialistas en marketing digital a optimizar el retorno de la inversión en marketing por actividad (es decir, URL de campaña). 
      </p>  
    </section>
  );
};

export default UTMContent;
