const UTMGenerator = ({ utmParams, children }) => {
  // Destructure the utmParams to get the individual UTM values
  const {
    landingPageUrl,
    utm_source,
    utm_medium,
    utm_objective,
    utm_campaign,
    utm_content,
    utm_term,
    utm_date,
    utm_lob,
    utm_country,
  } = utmParams;

  let utm = '';
  if (landingPageUrl && utm_source && utm_medium && utm_objective && utm_lob && utm_campaign) {
    // Combine utm_objective, utm_campaign, and utm_lob
    const campaignName = `${utm_objective}_${utm_lob}_${utm_campaign}`;
    
    // Combine utm_term, utm_date, and utm_country if any of them are provided
    const parts = [utm_term, utm_date, utm_country].filter(Boolean);
    const termAndDate = parts.length ? parts.join('_') : '';

    // Construct the UTM parameters with explicit encoding
    const utmParts = [
      `utm_source=${encodeURIComponent(utm_source)}`,
      `utm_medium=${encodeURIComponent(utm_medium)}`,
      `utm_campaign=${encodeURIComponent(campaignName)}`,
      utm_content && `utm_content=${encodeURIComponent(utm_content)}`,
      termAndDate && `utm_term=${encodeURIComponent(termAndDate)}`, // Check if termAndDate has any value
    ].filter(Boolean).join('&'); // Join the parts with '&' and filter out any undefined values

    utm = `${landingPageUrl}?${utmParts}`;
  }

  // Render children with the generated UTM
  return <>{children(utm)}</>;
};

export default UTMGenerator;
